(function ($) {

  $(document).ready(function () {

    // ********** jQuery plugins ************ 

    $('body').scrollToAnchors();

    if ($('body').hasClass('page_3')) {
      $('body').accessForm();
    }

    // $('#bottom').bottomize();

    $('.glyphicons').glyphClone();

    $(window).scrollTopArrow();

    $('body').fadePage();

    $('.bob-frame-show').bobframe();

  });

}(jQuery));