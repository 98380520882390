(function ($) {

  $.fn.accessForm = function () {

    // var msg = null;

    var successTimeout = null;

    var modalMsgHide = function (duration) {
      if (!duration) {
        duration = 0;
      }
      $('.modal-msg').fadeOut(duration, function () {
        $('.modal-msg .msg-content').hide();
      });
      // msg = null;
    };

    var modalMsgSuccess = function () {
      // msg = 'success';
      clearTimeout(successTimeout);
      $('.modal-msg .msg-success').show();
      $('.modal-msg').fadeIn();
      successTimeout = window.setTimeout(modalMsgHide, 3000, 300);
    };

    var modalMsgError = function () {
      clearTimeout(successTimeout);
        // console.log('msg', msg);
        // $('.modal-msg .msg-error > span').html(msg);
        $('.modal-msg .msg-error').show();
        $('.modal-msg').fadeIn();
    };


    var modalMsgWarning = function () {

      console.log(this);
      // msg = 'warning';
      clearTimeout(successTimeout);
      $('.modal-msg .msg-warning').show();
      $('.modal-msg').fadeIn();
    };

    var sendData = function (e) {

      e.preventDefault();

      $('.form-group').removeClass('has-error');

      modalMsgHide();

      $('#btn-submit', 'form.accessform').prop('disabled', true);

      var data = {};
      // var placeholder = {};
      var label = {};

      $(':input', e.target).each(function () {
        if (this.name) {
          if (this.name.substr(-2) === "[]") { // form fields with [] are arrays
            // get the cc[] values
            if (data[this.name.substr(0, this.name.length - 2)] === undefined) {
              data[this.name.substr(0, this.name.length - 2)] = [];
            }
            data[this.name.substr(0, this.name.length - 2)].push($(this).val().trim());
          } else {
            data[this.name] = $(this).val().trim();
            label[this.name] = $("label[for='"+$(this).attr('id')+"']", 'form.accessform').text().trim();
            // placeholder[this.name] = $(this).attr('placeholder') || "";
          }
        }
      });

      // console.log('data', data);

      var notValid = false;


      var validateEmail = function(email) {
        var re = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
        var regex = new RegExp(re);
        return regex.test(email);
      };


      if (data.email.trim() === "" || validateEmail(data.email.trim()) === false) {
        $('.form-group-email', 'form.accessform').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-email', 'form.accessform').removeClass('has-error');
        // data.description = placeholder.email + ": " + data.email + "\n\n" + data.description;
      }

      if (data.name.trim() === "") {
        $('.form-group-name', 'form.accessform').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-name', 'form.accessform').removeClass('has-error');
        // data.description = placeholder.name + ": " + data.name + "\n\n" + data.description;
      }


      var validatePhone = function(phone) {
        var re = /^[0-9\+\ \/\-\.\(\)]+$/;
        return re.test(phone);
      };

      if (data.phone.trim() === "" || validatePhone(data.phone.trim()) === false) {
        $('.form-group-phone', 'form.accessform').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-phone', 'form.accessform').removeClass('has-error');
        data.description = label.phone + ": " + data.phone + "\n\n" + data.description;
      }

      if (data.description.trim() === "") {
        $('.form-group-description', 'form.accessform').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-description', 'form.accessform').removeClass('has-error');
        // data.description = placeholder.name + ": " + data.name + "\n\n" + data.description;
      }

      if (notValid) {
        // enable button again
        $('#btn-submit', 'form.accessform').prop('disabled', false);
        modalMsgWarning();
        return;
      }

      $('document').spinner('show');


      $.ajax({
        url: "/mail",
        type: "POST",
        data: data,
        cache: false,
        success: function (response) {
          // show message
          modalMsgSuccess();

          // tracking by google tag manager
          // google tag manager
          if (window.dataLayer !== undefined) {
            window.dataLayer.push({
              "event": "conversionSuccess"
            });
          }

          $('document').spinner('hide');
          // $('form.accessform')[0].reset();
          $('form.accessform').addClass('hidden').trigger("reset");
          $('.success-content').removeClass('hidden');

          var e = $.Event('contentchange.bottomize');
          $(window).trigger(e);
        },
        error: function (err) {
          // console.log(err.status);
          // var errmsg = $('form.accessform').data('errmsg');
          modalMsgError();
          // enable button again
          $('#btn-submit', 'form.accessform').prop('disabled', false);
          // hide spinner
          $('document').spinner('hide');
        }
      });

    };

    $('.modal').on('hide.bob.modal', function () {
      // if (msg === 'error') {
        modalMsgHide(300);
      // }
    });

    $(document).off('.accessform');

    $(document).on('submit.accessform', 'form.accessform', function (e) {
      e.preventDefault();
      sendData(e);
    });

    $(document).on('click.accessform', '.modal-msg', function () {
      modalMsgHide(300);
    });

    return this;

  };

}(jQuery));